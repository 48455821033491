import styles from './ViewSliderMobileFullScreenType.module.scss';
import classnames from 'classnames/bind';
import React, { FC, useRef } from 'react';
import { ViewModeType } from '../../../../../interface/common/ViewMode';
import { HasOption } from '../../../../../interface/common/hasOption';
import ISlider from '../../../../../interface/layout/slider/ISlider';
import ViewSliderItem from '../../ViewSliderItem/ViewSliderItem';
import ViewSliderNavigatorProvider from '../../ViewSliderNavigator/ViewSliderNavigatorProvider';
import {
  MOBILE_OVERFLOW_FULLSCREEN_IMAGE_WIDTH,
  MOBILE_OVERFLOW_FULLSCREEN_SLIDER_GAP,
} from '../../../../../interface/layout/slider/sliderStyle/ISliderItemImageSize';
import { isOnOff } from '../../../../../constants/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper';

import 'swiper/css';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  slider: ISlider;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewSliderFullScreenType: FC<IProps & HasOption> = ({
  viewMode,
  slider,
  isCapture,
  isPreview,
  isEdit,
  options,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const sliderItems = slider.items;
  const sliderStyle = slider.style;
  const isAutoPlayMode = isOnOff(sliderStyle.autoSlide.autoMode);
  const autoPlayDirection = sliderStyle.autoSlide.direction;
  const DUPLICATED_SLIDER_COUNT = 3;
  const AUTO_PLAY_DURATION = 3;

  const handleNavigatorClick = (direction: 'left' | 'right') => {
    if (!swiperRef.current) return;
    if (direction === 'left') {
      swiperRef.current.slidePrev();
    } else if (direction === 'right') {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className={cx('slider-container')}>
      <ViewSliderNavigatorProvider
        sliderStyle={sliderStyle}
        canMovePrev
        onClickPrev={() => handleNavigatorClick('left')}
        canMoveNext
        onClickNext={() => handleNavigatorClick('right')}
      >
        {isAutoPlayMode ? (
          <div className={cx('slider-track', autoPlayDirection)}>
            {[...Array(DUPLICATED_SLIDER_COUNT)].map((_, index) => (
              <div
                className={cx('slider-content')}
                style={{ animationDuration: `${sliderItems.length * AUTO_PLAY_DURATION}s` }}
              >
                {sliderItems.map((sliderItem) => (
                  <ViewSliderItem
                    key={`${sliderItem.id}-duplicated-${index}`}
                    sliderItem={sliderItem}
                    sliderStyle={sliderStyle}
                    viewMode={viewMode}
                    isCapture={isCapture}
                    isPreview={isPreview}
                    isEdit={isEdit}
                    options={options}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : (
          <Swiper
            grabCursor
            spaceBetween={MOBILE_OVERFLOW_FULLSCREEN_SLIDER_GAP}
            slidesOffsetBefore={MOBILE_OVERFLOW_FULLSCREEN_SLIDER_GAP}
            slidesOffsetAfter={MOBILE_OVERFLOW_FULLSCREEN_SLIDER_GAP}
            slidesPerView={'auto'}
            loop
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {[...Array(3)].map((_, index) =>
              sliderItems.map((sliderItem) => (
                <SwiperSlide
                  style={{
                    width: Math.min(sliderStyle.sliderItemImageSize.width, MOBILE_OVERFLOW_FULLSCREEN_IMAGE_WIDTH),
                  }}
                >
                  <ViewSliderItem
                    key={`${sliderItem.id}-duplicated-${index}`}
                    sliderItem={sliderItem}
                    sliderStyle={sliderStyle}
                    viewMode={viewMode}
                    isCapture={isCapture}
                    isPreview={isPreview}
                    isEdit={isEdit}
                    options={options}
                  />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        )}
      </ViewSliderNavigatorProvider>
    </div>
  );
};

export default ViewSliderFullScreenType;
