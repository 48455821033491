import classnames from 'classnames/bind';
import { FC, useContext, useMemo } from 'react';
import { Descendant } from 'slate';
import SlateEditor from '../../../common/textEditor/SlateEditor';
import { isOnOff, OFF } from '../../../constants/common';
import { EDITOR_BLOCK_WIDTH } from '../../../constants/editor/block.constant';
import { FontInjectedContext } from '../../../context/font/FontInjectedContext';
import { HasOption } from '../../../interface/common/hasOption';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { BANNER_TYPE } from '../../../interface/layout/banner/banner.constant';
import IViewLayout from '../../../interface/layout/IViewLayout';
import styles from './ViewBannerText.module.scss';
const cx = classnames.bind(styles);

interface IViewBannerTextProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isPreview?: boolean;
  value?: Descendant[] | string;
}

const ViewBannerText: FC<IViewBannerTextProps & HasOption> = ({
  viewMode,
  viewLayout,
  isPreview,
  options,
  value: _value,
}) => {
  const fontProps = useContext(FontInjectedContext);
  const value = typeof _value === 'string' ? JSON.parse(_value) : _value;

  const bannerType = viewLayout.banner?.type;
  const desktopStyle =
    bannerType === BANNER_TYPE.BASIC
      ? viewLayout.banner?.bannerBasic.desktopStyle
      : viewLayout.banner?.bannerSlide.desktopStyle;
  const contentWidth = useMemo(() => {
    if (viewMode === VIEW_MODE.PC && isOnOff(desktopStyle?.textStyle.textRatioFixToggle ?? OFF))
      return EDITOR_BLOCK_WIDTH;

    return '100%';
  }, []);
  const textLocationType = desktopStyle?.textStyle.textLocationType;

  return (
    <div className={cx('container', textLocationType)}>
      <div className={cx('content')} style={{ width: contentWidth }}>
        <SlateEditor
          value={value}
          onChange={() => {}}
          disabled={true}
          viewMode={viewMode}
          isPreview={isPreview}
          options={options}
          {...fontProps}
        />
      </div>
    </div>
  );
};

export default ViewBannerText;
